function LogoIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 32 32">
      <path id="Path_13198" data-name="Path 13198" d="M14.828.088,14.733,0,0,13.882.063,28.361l.015.007a3.068,3.068,0,0,0,3.068,2.767c.026,0,.051,0,.077,0l26.629-.112-.076-17.267L14.922,0ZM3.442,29.617a1.406,1.406,0,1,1,1.406-1.406,1.406,1.406,0,0,1-1.406,1.406M7.11,28.328a4.287,4.287,0,0,0-3.45-3.405L3.644,22.04a7.138,7.138,0,0,1,6.35,6.278Zm5.749-.019a9.989,9.989,0,0,0-9.232-9.133l-.016-2.859A12.84,12.84,0,0,1,15.719,28.3Z" fill="currentColor"></path>
    </svg>
  )

}

export default LogoIcon